import React, { useState } from 'react'
import Layout from '../../components/common/Layout'
import Fade from 'react-reveal/Fade'
import PageHeader from '../../components/common/PageHeader'
import ContentSection from '../../components/common/ContentSection'
import ElevateSection from '../../components/common/ElevateSection'
import Button from '../../components/common/Button'
import { Space } from '@mantine/core'
import Spotlight from '../../components/home/Spotlight'

const WidgetsPage = () => {
  return (
    <Layout
      meta={{
        description:
          'Visualize millions of data points with ease. Create and share interactive dashboards that take your data storytelling to the next level.',
        title: 'Builder mode | Atlas',
        type: 'website'
      }}
      title={'Builder mode | Atlas'}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader
            title={'Build interactive maps in minutes'}
            description={
              'Create and share interactive maps that take your data storytelling to the next level. No coding required.'
            }
            video="https://cdn.atlas.co/landing-pages/Builder%20Launch%20Main.mp4"
          />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection centered>
            <h2>No coding required</h2>
            <p>
              With Builder Mode, you don’t need to be a developer to create
              powerful map applications. Everything is designed for ease of use,
              so you can build interactive maps without writing a single line of
              code.
            </p>
          </ContentSection>
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/drag-drop-builder.mp4'}
            icon={{ src: '/icons/editor-mode.svg', color: '#F5F7FA' }}
          >
            <h3>Drag-and-drop interface</h3>
            <p>
              Building custom maps has never been easier. Builder Mode offers a
              fully drag-and-drop interface, allowing you to quickly add and
              arrange elements.
            </p>
            <p>
              Design your map applications exactly how you want them, all
              through an intuitive and seamless workflow.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={
              'https://cdn.atlas.co/landing-pages/flexible-widget-sections.mp4'
            }
            reverse
            icon={{ src: '/icons/range.svg', color: '#CDE4E1' }}
          >
            <h3>Flexible widget sections</h3>
            <p>
              With Builder Mode, you can easily create interactive maps that fit
              your needs. Add data filters, visualizations, and other elements
              into flexible sections around your map.
            </p>
            <p>
              Customize these sections to display data insights in a way that
              best tells your story—whether it’s comparing categories or showing
              trends over time.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={
              'https://cdn.atlas.co/landing-pages/web-ready-interactive.mp4'
            }
            icon={{
              src: '/icons/map.svg',
              color: '#F5F7FA'
            }}
          >
            <h3>Web-ready, interactive maps</h3>
            <p>
              Builder Mode produces maps that are ready to be shared and
              embedded anywhere. These maps are fully interactive and optimized
              for the web, making it simple to bring your data to life in real
              time.
            </p>
            <p>
              Whether you’re building a public-facing app or an internal
              dashboard, your maps are built to engage users instantly.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ElevateSection
            title={'Designed to be easily updated'}
            subtitle={
              'Web maps are live assets designed to be edited, and any changes will be automatically updated synced across all members.'
            }
          />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <Spotlight />
        </Fade>
      </main>
    </Layout>
  )
}

export default WidgetsPage
